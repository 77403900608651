/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import * as sH from 'sanitize-html'
import { Transition } from '@headlessui/react'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { faChevronLeft as arrowLeft, faChevronRight as arrowRight } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import ProjectsItem from './Projects/ProjectsItem'
import NavButton from '../../utils/NavButton'

import 'swiper/css'
import 'swiper/css/navigation'

const Projectlisting = ({ items, scope, limit, layout, slider, exclude }) => {
  const [allItems] = useState(items)
  const maxPosts = 6
  // State for the list
  const [list, setList] = useState([...allItems.slice(0, maxPosts)])
  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allItems.length > maxPosts)
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }
  // Handle loading more articles
  useEffect(() => {
    setList([...allItems.slice(0, maxPosts)])
    }, [allItems]) //eslint-disable-line

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allItems.length
      const nextResults = isMore ? allItems.slice(currentLength, currentLength + maxPosts) : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
      }, [loadMore, hasMore]) //eslint-disable-line

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < allItems.length
    setHasMore(isMore)
    }, [list]) //eslint-disable-line

  const { t } = useTranslation()

  const LoadMore = styled.button`
    ${tw`mx-auto inline-block bg-primary transition-all font-bold px-10 py-3`}
  `
  const Showing = styled.small`
    ${tw`text-ricoBlack opacity-50 py-3`}
  `
  const Footer = styled.footer`
    ${tw`w-full flex flex-col place-items-center`}
  `
  const ButtonGroup = styled.div`
    ${tw`hidden md:flex relative cursor-pointer`}
  `
  const Icon = styled(props => <FontAwesomeSvgIcon {...props} />)`
    ${tw`text-sm`}
  `

  const aniOpen = 'opacity-100'
  const aniClose = 'opacity-0'
  const aniClass = 'h-full w-full transition-opacity ease-linear duration-300'

  const animationProps = {
    className: aniClass,
    enterFrom: aniClose,
    enterTo: aniOpen,
    entered: aniOpen,
  }

  const breakpoints = {
    0: { slidesPerView: 1.2, centeredSlides: true, slidesPerGroup: 1 },
    768: { slidesPerView: 2, slidesPerGroup: 2 },
    1024: { slidesPerView: 3, slidesPerGroup: 3 },
  }

  const gridClasses = 'md:grid md:grid-cols-2 lg:grid-cols-3 auto-rows-max'

  SwiperCore.use([Navigation])

  const swiperRef = React.useRef(null)

  const [leftDisabled, disableLeft] = useState(true)
  const [rightDisabled, disableRight] = useState(false)

  return (
    <>
      {slider === 'slider' && (
        <ButtonGroup>
          <NavButton direction="Previous" off={leftDisabled} swiperObj={swiperRef}>
            <Icon icon={arrowLeft} />
          </NavButton>
          <NavButton direction="Next" off={rightDisabled} swiperObj={swiperRef}>
            <Icon icon={arrowRight} />
          </NavButton>
        </ButtonGroup>
      )}
      <Transition className="w-full" show>
        {allItems.length > 0 ? (
          slider === 'slider' ? (
            <Swiper
              className="h-full"
              spaceBetween={20}
              simulateTouch={false}
              ref={swiperRef}
              breakpoints={breakpoints}
              // eslint-disable-next-line no-sequences
              onFromEdge={() => (disableRight(false), disableLeft(false))}
              onReachBeginning={() => disableLeft(true)}
              onReachEnd={() => disableRight(true)}
            >
              {allItems
                .slice(0, limit)
                .filter(item => exclude !== item.id)
                .map(item => (
                  <SwiperSlide key={item.id}>
                    <ProjectsItem item={item} layout={layout} slider={slider} />
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <div className={`w-full px-8 md:px-0 ${layout === 'grid' ? gridClasses : ''}`}>
              {list.slice(0, limit)?.map(item => (
                <Transition.Child key={item.id} {...animationProps}>
                  <ProjectsItem item={item} layout={layout} slider={slider} />
                </Transition.Child>
              ))}
            </div>
          )
        ) : (
          <>{t('Inget att visa')}</>
        )}
      </Transition>
      {scope === 'large' && slider === 'loadmore' && (
        <Footer>
          {hasMore ? (
            <>
              <Showing>
                {t('Visar')} 1-{list.length} {t('av')} {allItems.length}
              </Showing>
              <LoadMore onClick={handleLoadMore}>{t('Ladda fler')}</LoadMore>
            </>
          ) : (
            <Showing>({t('Visar alla')})</Showing>
          )}
        </Footer>
      )}
    </>
  )
}

const Projects = ({ data }) => {
  const projects = useStaticQuery(graphql`
    {
      sv: allWpProject(sort: { order: DESC, fields: [date] }, filter: { language: { code: { eq: SV } } }) {
        nodes {
          ...ProjectPreviewContent
        }
      }
      en: allWpProject(sort: { order: DESC, fields: [date] }, filter: { language: { code: { eq: EN } } }) {
        nodes {
          ...ProjectPreviewContent
        }
      }
    }
  `)

  const { en, sv } = projects

  const { language } = useI18next()

  const items = language === 'en' ? en.nodes : sv.nodes

  const { title, scope, sideLink, backgroundImage, layout, status, slider, exclude } = data

  const image = getImage(backgroundImage?.image)

  const filteredItems = status.length > 1 ? items : items.filter(item => item.projectFields.current === status[0])

  const limit = scope === 'large' ? 999 : scope === 'small' ? 3 : 3

  const backgroundFluidImageStack = [`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))`, image]

  const Container = styled.div`
    ${tw`bg-offWhite text-white`}
  `
  const Wrapper = styled.section`
    ${tw`max-w-7xl mx-auto py-16 flex flex-col md:flex-row md:flex-wrap content-between text-white justify-between md:px-8`}
  `
  const SideLink = styled(props => <Link {...props} />)`
    ${tw`ml-8 order-last md:order-none font-bold text-white border-b border-white self-start pl-6 mb-1 no-underline inline-block mt-5 z-30 md:mr-3`}
  `
  const ItemTitle = styled.h2`
    ${tw`px-10 md:px-3 text-4xl md:text-5xl font-light `}
    ${image ? tw`text-white mb-8 mt-2 ` : tw`text-ricoBlack mb-10`};
  `

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {filteredItems.length > 0 && (
        <Container className="mx-auto">
          <BgImage keepStatic image={backgroundFluidImageStack}>
            <Wrapper className="w-full z-30">
              {title && <ItemTitle dangerouslySetInnerHTML={{ __html: sH(title) }} />}
              {sideLink && <SideLink to={sideLink.url} dangerouslySetInnerHTML={{ __html: sH(sideLink.title) }} />}
              <Projectlisting
                limit={limit}
                items={filteredItems}
                scope={scope}
                hasImage={!!image}
                layout={layout}
                slider={slider}
                exclude={exclude}
              />
            </Wrapper>
          </BgImage>
        </Container>
      )}
    </>
  )
}

Projects.propTypes = {
  title: PropTypes.string,
  scope: PropTypes.string,
  language: PropTypes.string,
}

Projects.defaultProps = {
  title: 'Fler nyheter',
  language: 'sv',
  scope: 'none',
}

export default Projects

export const projectsData = graphql`
  fragment Projects on WpPage_Pagebuilder_Layouts_Projects {
    fieldGroupName
    title
    scope
    status
    slider
    layout
    sideLink {
      target
      url
      title
    }
    backgroundImage {
      image: localFile {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
      title
    }
  }
`
