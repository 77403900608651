/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import * as sH from 'sanitize-html'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ProjectsItem = ({ item, layout, slider }) => {
  const {
    projectFields: { excerpt } = {},
    title,
    uri,
    locations: { nodes: locations } = {},
    featuredImage,
  } = item || {}

  const { t } = useTranslation()

  const Wrapper = styled.div`
    ${tw`py-5 h-full`}
    ${slider === 'slider' ? tw`md:px-0 h-full` : tw`md:px-3`};
  `
  const StyledLink = styled(props => <Link {...props} />)`
    ${tw`flex flex-col`}
    ${layout === 'grid' ? tw`h-full` : tw`lg:flex-row`};
  `
  const Container = styled.div`
    ${tw`bg-white flex flex-col w-full`}
    ${layout === 'grid' ? tw`justify-between` : tw`lg:flex-row`};
    ${slider === 'slider' ? tw`min-h-[425px] md:min-h-[485px] lg:min-h-[485px] xl:min-h-[485px]` : tw``};
  `
  const Content = styled.div`
    ${tw``}
    ${layout === 'grid'
      ? tw`px-6 flex-grow flex justify-between flex-col md:px-8 py-8 before:content-[''] before:bg-primary relative before:absolute before:top-[-3px] before:text-5xl before:z-30 before:w-24 before:h-[6px]`
      : tw`w-full lg:w-2/3 py-6 pb-8 md:pb-6 md:py-10 px-6 md:px-12 bg-white z-30 `};
  `
  const Location = styled.small`
    ${tw`text-primary text-base uppercase`}
  `
  const Image = styled(props => <GatsbyImage {...props} />)`
    ${tw`w-full`}
    ${layout === 'grid' ? tw`` : tw`lg:w-1/3`};
  `
  const Title = styled.h3`
    ${tw`text-ricoDarkGray font-display mb-3 font-light text-3xl md:text-4xl`}
  `
  const Excerpt = styled.p``

  const Readmore = styled.p`
    ${tw`text-ricoDarkGray hover:text-primary text-sm mt-4 md:text-base`}
  `

  const imD = featuredImage ? getImage(featuredImage.node.localFile.childImageSharp.gatsbyImageData) : ''

  return (
    <Wrapper>
      <StyledLink to={uri}>
        <Container className="grow">
          {imD && <Image className="" image={imD} alt={title} />}
          <Content>
            <div>
              {locations.length > 0 && <Location dangerouslySetInnerHTML={{ __html: sH(locations[0].name) }} />}
              {title && <Title dangerouslySetInnerHTML={{ __html: sH(title) }} />}
            </div>
            {excerpt && layout !== 'grid' && (
              <Excerpt className="prose" dangerouslySetInnerHTML={{ __html: sH(excerpt) }} />
            )}
            <Readmore>{t('Läs mer >')}</Readmore>
          </Content>
        </Container>
      </StyledLink>
    </Wrapper>
  )
}

ProjectsItem.propTypes = {
  item: PropTypes.object.isRequired,
  layout: PropTypes.string,
  slider: PropTypes.string,
}

export default ProjectsItem
